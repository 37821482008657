.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0);
  backdrop-filter: blur(5px);
  z-index: 999; 
  flex-direction: column;
  /* animation: fadeInOut 1s ease-in-out forwards; */
  margin-top: 30px;
  padding-top: 30px;
  height: 80%;
  padding-bottom: 45px;
}

@keyframes fadeInOut {
  0% {
    background: rgba(0, 0, 0, 0); 
  }
  50% {
    background: rgba(0, 0, 0, 0.2); 
  }
  100% {
    background: rgba(0, 0, 0, 0.2); 
  }
}

@keyframes fadeOut {
  0% {
    background: rgba(0, 0, 0, 0.2);
  }
  100% {
    background: rgba(0, 0, 0, 0);
    visibility: hidden;
  }
}

.loader {
  position: relative;
  width: 80px;
  height: 80px;
}

.cShape {
  width: 70px;
  height: 70px;
  border: 6px solid #3498db;
  border-radius: 50%;
  border-left-color: transparent;
  animation: spin 1.2s linear infinite;
  position: absolute;
}

.stick {
  position: absolute;
  width: 15px;
  height: auto;
  left: 50%;
  top: 40%;
  transform: translate(-58%, -33%);
  margin: 0 !important;
  width: 75% !important;
}

.loadingMsg {
  transform: translate(0%, 150%);
  font-size: 1.4rem;
  color: var(--color-secondary);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}